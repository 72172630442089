import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AppRoute from './components/Routes/AppRoute';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAUTNGLQ53vNaPeyWe2b8CsbB3lq6laOmA",
    authDomain: "agplex-c4e92.firebaseapp.com",
    projectId: "agplex-c4e92",
    storageBucket: "agplex-c4e92.appspot.com",
    messagingSenderId: "784136485633",
    appId: "1:784136485633:web:5399764ea1eb4697423900",
    measurementId: "G-J4E2WT2FCB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function App() {
  return (
    <div className="App">
      <Header/>
      <AppRoute/>
      <Footer/>
    </div>
  );
}

export default App;
