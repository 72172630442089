import React, { useEffect } from 'react';

const GoogleTranslateComponent = () => {
    useEffect(() => {
        // Check if the script is already present to avoid adding it multiple times
        if (!document.querySelector('.google-translate-script')) {
            const googleTranslateScript = document.createElement('script');
            googleTranslateScript.className = 'google-translate-script';
            googleTranslateScript.type = 'text/javascript';
            googleTranslateScript.async = true;
            googleTranslateScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            document.body.appendChild(googleTranslateScript);
        }

        window.googleTranslateElementInit = function() {
            new window.google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
        };

        // Optional: Cleanup the script when the component unmounts
        return () => {
            const script = document.querySelector('.google-translate-script');
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    return <div id="google_translate_element" className="language-dropdown"></div>;
};

export default GoogleTranslateComponent;
